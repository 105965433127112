
const base64EncodeUnicode_HTML = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  var utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );
  return btoa(utf8Bytes);
};

export const base64DecodeUnicode_HTML = (str) => {
  // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
  const percentEncodedStr = atob(str)
    .split("")
    .map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
    .join("");

  return decodeURIComponent(percentEncodedStr);
};

export const findSuperParentWithClass = (element, className) => {
  // Check if the element itself has the class
  if (element.classList.contains(className)) {
    return element; // Return the element itself
  }
  var parent = element.parentNode;
  while (parent !== null) {
    if (parent.classList.contains(className)) {
        return parent; // Found the super parent with the class
    }
    parent = parent.parentNode; // Move to the next parent node
}
  return null; // Super parent with the class not found
}

  