// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviewfeild {
  padding: 0.95rem 0.75rem;
  font-size: 1rem;
  line-height: normal;
}
.gblfont {
  font-family: var(--Poppins-family);
}
.reviewstar i {
  cursor: pointer;
}
.reviewList {
  padding-top: 1.5rem;
}
.appointmentSlot .availableSlot,
.appointmentSlot .breakHour {
  color: #fff;
  padding: 11px 0;
  width: 100%;
  max-width: 108px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.no-slots {
  padding: 30px 20px;
  background-color: #f8fafc;
  width: 100%;
  border-radius: 8px;
}
.appointmentSlot .breakHour {
  background: #cecece;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./assets/css/Theme1.css"],"names":[],"mappings":"AAAA;EAAa,wBAAA;EAAsB,eAAA;EAAe,mBAAA;AAIlD;AAJqE;EAAS,kCAAA;AAO9E;AAPgH;EAAc,eAAA;AAU9H;AAV6I;EAAY,mBAAA;AAazJ;AAb4K;;EAA4D,WAAA;EAAW,eAAA;EAAe,WAAA;EAAW,gBAAA;EAAgB,kBAAA;EAAkB,kBAAA;EAAkB,eAAA;AAuBjU;AAvBgV;EAAU,kBAAA;EAAkB,yBAAA;EAAyB,WAAA;EAAW,kBAAA;AA6BhZ;AA7Bka;EAA4B,mBAAA;EAAmB,mBAAA;AAiCjd","sourcesContent":[".reviewfeild{padding:.95rem .75rem;font-size:1rem;line-height:normal}.gblfont{font-family:var(--Poppins-family)}.reviewstar i{cursor:pointer}.reviewList{padding-top:1.5rem}.appointmentSlot .availableSlot,.appointmentSlot .breakHour{color:#fff;padding:11px 0;width:100%;max-width:108px;text-align:center;border-radius:4px;cursor:pointer}.no-slots{padding:30px 20px;background-color:#f8fafc;width:100%;border-radius:8px}.appointmentSlot .breakHour{background:#cecece;cursor:not-allowed}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
