import { builderData, StyleObj } from "./BuilderProps"; 
import { GetAPI_deleteAPI_method } from "./Api";
import moment from "moment";
import Handlebars from 'handlebars';

const ApJsonData = async (apType) => {
  return (apType == "serviceMenusList")
    ? builderData.OnboardingData[apType]
    : (builderData[apType].length) ? builderData[apType] : await GetAPI_deleteAPI_method(apType, "GET");
};

export const appointment_layout = async (widgetName) => {
  const WName = (!widgetName) ? builderData.widgetName : widgetName;
  const {apType} = StyleObj[WName];
  const OnbData = await ApJsonData(apType);
    if (!!OnbData) { 
      const layout = (WName === 'Service') ? StyleObj[WName].layout : 'grid';
  
      const newobj = OnbData.map((item)=> ({...item, currencySymbol:builderData.currencySymbol , apType:apType,layout:layout,WName:WName }));
  
      const template = document.getElementById(`template_APOLayout${layout}`).innerHTML;
      const compiledTemplate = Handlebars.compile(template);
      
      if (layout !== 'summary') {
        const commonHtmlof_gridlist = document.getElementById('commonHtmlof_gridlist').innerHTML
        Handlebars.registerPartial('commonHtmlof_gridlist', commonHtmlof_gridlist);
      }
      
      // Apply styles to the compiled template
      Handlebars.registerHelper('applyStyles', function(stylesKey, color) {
        var styles = StyleObj[WName][stylesKey]; // Retrieve styles from StyleObj
        //if (!styles) return ''; // Check if styles is undefined or null
         return new Handlebars.SafeString(`style=" ${StylejsonFN(styles)}; ${!color.data && `color:${color}`}"`);
      });

      // Apply event color to the compiled template
      Handlebars.registerHelper('eventColor', function(color, options) {
         return new Handlebars.SafeString(`style="color: ${color}"`);
      });
  
      // check string equal to
      Handlebars.registerHelper('if_eq', function(a, b, opts) {
        return a === b ? opts.fn(this) : opts.inverse(this);
      });
  
      // check string not equal to
      Handlebars.registerHelper('if_noteq', function(a, b, opts) {
        return a !== b ? opts.fn(this) : opts.inverse(this);
      });
  
      // format dates and times
      Handlebars.registerHelper('formatDateTime', function(dateString, format) {
        return window.momentFormat(dateString, format);
      });
  
      const updatedHTML = compiledTemplate({appointmentData: newobj});
      if(WName === 'Service') {
        const appendEle = document.querySelector(`.${WName} .ServiceList`);
        appendEle.innerHTML = updatedHTML;
      }
      else {
        return updatedHTML;
      }
    }
  };

export const staff_MList = async(WName) => {
    const template = document.getElementById("template_stafflist").innerHTML;
    const compiledTemplate = Handlebars.compile(template);

    // first letter of string
    Handlebars.registerHelper('returnOnlyZeroindex', function(name, optios) {
        return name.charAt(0).toUpperCase();
    });
    const updatedHTML = compiledTemplate(builderData.OnboardingData);
    return updatedHTML;
}

window.momentFormat = (data, format) => {
    return `${moment(new Date(data)).format(format)}`
}

const StylejsonFN = (json) => {
    return Object.keys(json)
      .map(function (key) {
        return key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() + ": " + json[key] + ";";
      })
      .join("\n");
  };