import { builderData } from "./BuilderProps"; 
import Handlebars from 'handlebars';
import moment from "moment";
export const OpeningHours = () => {
  const openingH = document.querySelector(".Bhours-list");
  const template = document.getElementById('template_Bhours').innerHTML;
  const compiledTemplate = Handlebars.compile(template);

  // format dates and times
  Handlebars.registerHelper('formatDateTime', function(dateString, options) {
    return window.momentFormat(dateString, "HH:mm");
  });
  const updatedHTML = compiledTemplate(builderData.OnboardingData);
  openingH.innerHTML = updatedHTML;

  // const HoursData = builderData.OnboardingData.businessHoursList;
  // const openingH = document.querySelector(".BusinessH-wrapper");
  // let Hlist = "";
  // if (!!HoursData) {
  //   HoursData.forEach((Hours) => {
  //     let clonedItem = openingH.querySelector("li").cloneNode(true);
  //     const strat = moment(Hours.from).format("HH:mm");
  //     const end = moment(Hours.to).format("HH:mm");
  //     clonedItem.innerHTML = clonedItem.innerHTML.replace("{day}", Hours.day.substring(0, 3)).replace("{hours}", Hours.isOpened ? (strat + " - " + end) : 'Closed');
  //     clonedItem.style.color = (Hours.isOpened ? '' : '#ccc');

  //     Hlist += clonedItem.outerHTML;
  //   });
  //   openingH.innerHTML = Hlist;
    
  // }
  // if (builderData.OnboardingData.brandBannerUrl) {
  //   const Image = document.querySelector(".Image");
  //   console.log(builderData.OnboardingData)
  //   Image.querySelector('img').src = builderData.OnboardingData.brandLogoUrl

  // }
    
};

export const ContactData = () => {
  // const ContactData = builderData.OnboardingData.businessAddressList[0];
  // const contactData = document.querySelector(".Baddress-detail");
  // contactData.innerHTML = `<li class="p-2 flex items-center gap-3"><i class="fa-solid fa-location-dot"></i> <a href="javascript:void(0)">${
  //   ContactData.address
  // }, ${ContactData.city}, ${ContactData.country.substring(0, 3)}, ${
  //   ContactData.zip
  // }</a></li>`;
  const openingH = document.querySelector(".Baddress-detail");
  const template = document.getElementById('template_Baddress').innerHTML;
  const compiledTemplate = Handlebars.compile(template);

  const updatedHTML = compiledTemplate( builderData.OnboardingData);
  openingH.innerHTML = updatedHTML;
};
