// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --date-seleced-color: #f67280;
}
.datepicker {
  width: 100%;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
}
.datepicker-days table {
  width: 100%;
  border-collapse: collapse;
}
.datepicker th.prev,
.datepicker th.next,
.datepicker th.datepicker-switch {
  border-bottom: 1px solid var(--gray-100);
  height: 45px;
}
.datepicker td,
.datepicker th {
  text-align: center;
  height: 40px;
  position: relative;
  padding: 0;
  font-size: 1rem;
}
.datepicker table tr td.today,
.datepicker table tr td.active.active {
  font-weight: 500;
}
.datepicker table tr td.day:not(td.old,td.disabled):hover,
.datepicker table tr td.today,
.datepicker table tr td.active.active {
  color: #fff;
  cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.disabled {
  color: #81889a;
  opacity: 0.4;
}
.datepicker table tr td:hover:after,
.datepicker table tr td.today:after,
.datepicker table tr td.active:after {
  content: "";
  background-color: var(--date-seleced-color);
  height: 40px;
  width: 40px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  z-index: -1;
  border-radius: 50%;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./assets/css/CalenderTheme1.css"],"names":[],"mappings":"AAAA;EAAM,6BAAA;AAEN;AAFoC;EAAY,WAAA;EAAW,iCAAA;EAAiC,kBAAA;AAO5F;AAP8G;EAAuB,WAAA;EAAW,yBAAA;AAWhJ;AAXyK;;;EAAyE,wCAAA;EAAwC,YAAA;AAiB1R;AAjBsS;;EAA8B,kBAAA;EAAkB,YAAA;EAAY,kBAAA;EAAkB,UAAA;EAAU,eAAA;AAyB9X;AAzB6Y;;EAAoE,gBAAA;AA6Bjd;AA7Bie;;;EAA8H,WAAA;EAAW,eAAA;AAmC1mB;AAnCynB;;EAA6D,cAAA;EAAc,YAAA;AAwCpsB;AAxC+sB;;;EAA6G,WAAA;EAAW,2CAAA;EAA2C,YAAA;EAAY,WAAA;EAAW,kBAAA;EAAkB,OAAA;EAAO,QAAA;EAAQ,kBAAA;EAAkB,cAAA;EAAc,WAAA;EAAW,kBAAA;EAAkB,QAAA;EAAQ,SAAA;EAAS,2BAAA;AA0Dx/B","sourcesContent":[":root{--date-seleced-color: #f67280}.datepicker{width:100%;border:1px solid var(--gray-100);border-radius:8px}.datepicker-days table{width:100%;border-collapse:collapse}.datepicker th.prev,.datepicker th.next,.datepicker th.datepicker-switch{border-bottom:1px solid var(--gray-100);height:45px}.datepicker td,.datepicker th{text-align:center;height:40px;position:relative;padding:0;font-size:1rem}.datepicker table tr td.today,.datepicker table tr td.active.active{font-weight:500}.datepicker table tr td.day:not(td.old,td.disabled):hover,.datepicker table tr td.today,.datepicker table tr td.active.active{color:#fff;cursor:pointer}.datepicker table tr td.old,.datepicker table tr td.disabled{color:#81889a;opacity:.4}.datepicker table tr td:hover:after,.datepicker table tr td.today:after,.datepicker table tr td.active:after{content:\"\";background-color:var(--date-seleced-color);height:40px;width:40px;position:absolute;left:0;right:0;text-align:center;margin:0 auto;z-index:-1;border-radius:50%;top:50%;bottom:0;transform:translateY(-50%)}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
