import { GetAPI_deleteAPI_method, urlParams } from "./Api";
import { StyleObj } from "./BuilderProps"; 
import { OnboardingData } from "./OnboardingData";
import { base64DecodeUnicode_HTML } from "./CommonMethod";
import { PageRouting } from "./Routes";

(async function () {
  // template HTML bind
  await GetAPI_deleteAPI_method(`MyThemeById?ThemeId=${urlParams.get('id')}`, "GET").then(async (result) => {
    const insertData = document.getElementById("insertData");
    insertData.innerHTML = base64DecodeUnicode_HTML(result.html);
    Object.assign(StyleObj, JSON.parse(result.styleJsonMetaData))
    await OnboardingData();
    PageRouting();
  });
})();
