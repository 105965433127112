export const urlParams = new URLSearchParams(window.location.search);
// let token = `Bearer ${urlParams.get('tk')}`;
let token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjZlMjVlYzljLTMwZGUtNDVjZS1hZTRjLWE1ZWUzOGQ5ODdmMiIsIm5hbWUiOiI2NWU5NTRlMzRkMWMzZjc2YWU0OWIxOWIiLCJlbWFpbCI6InZhcnVuQHJlZG9yYW5nZXRlY2hub2xvZ2llcy5jb20iLCJqdGkiOiJiYThhYWUzMC1iNTExLTQwZTktYTg3Ny01NDZlMWVjMTNlOWIiLCJVc2VySWQiOiI2NWU5NTRlMzRkMWMzZjc2YWU0OWIxOWIiLCJuYmYiOjE3MTUzMzk0OTcsImV4cCI6MTcxNTQxMTQ5NywiaWF0IjoxNzE1MzM5NDk3LCJpc3MiOiJodHRwOi8vcm90LURldi8iLCJhdWQiOiJBcGl2MiJ9.-5pJMLlzNKa4tEpo_DARq9kEhAMCPmkJKCbeAyRWxSsazOZQAyAMgwQqJHdCk7hT19unwO584Im2sLMoHd_P_g`;
const APIURL = "https://appapidev.appointusonline.com/";

export const PostAPI_PutAPI_method = async (url, method, post_data) => {
  try {
    return await fetch(`${APIURL}${url}`, {
      withCredentials: true,
      method: method,
      body: JSON.stringify(post_data),
      headers: {
        "content-type": "application/json",
        Authorization: token ? token : "",
      },
    })
      .then(async (res) => await res.json())
      .catch((err) => console.error(err));
  } catch (err) {
    console.warn(err);
  }
};
export const GetAPI_deleteAPI_method = async (url, method, target) => {
  try {
    return await fetch(`${APIURL}${url}`, {
      withCredentials: true,
      method: method,
      headers: {
        Authorization: token,
        target: target == undefined ? "" : target,
      },
    })
      .then(async (res) => await res.json())
      .catch((err) => console.error(err));
  } catch (err) {
    console.warn(err);
  }
};
