import { builderData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { appointment_layout } from "./ServiceFilterHtml";
import { OpeningHours, ContactData } from "./DaynamicDataBind";

export const OnboardingData = async () => {
  GetAPI_deleteAPI_method("Onboarding", "GET").then((result) => {
    builderData.OnboardingData = result; // service List
    builderData.currencySymbol = result.userCurrencySymbol; // store Currency Symbol gobbly
    appointment_layout('Service');
    OpeningHours(); // update opening hours
    ContactData(); // update contact
  });
};