import Navigo from "navigo";

const router = new Navigo("/", { hash: true });

export const PageRouting = () => {

  router
    .on({
      "/": () => {
        navigatePage("Homepage");
        navActiveClass();
      },
      "/review": () => {
        navigatePage("Reviewpage");
        navActiveClass();
      },
      "/bookings/type/upcoming": () => {
        navigatePage("Bookingpage");
        navActiveClass();
      },
      "/Service": () => {
        navigateSection()
    },
    })
    .resolve();
};

const navigateSection = () => {
    console.log();
}

const navigatePage = (value) => {
  const Allpage = document.querySelectorAll(".page-wrapper");
  for (const page of Allpage) {
    const thisPage = page.classList.contains(value);
    page.classList[thisPage ? "remove" : "add"]("hidden");
    if (page.classList.contains("Navbar")) {
      page.classList.remove("hidden");
    }
  }
};

// Function to update the active class on the menu items
const navActiveClass = () => {
    const navLink = document.querySelectorAll('.nav-link');
    navLink.forEach(item => {
        const itemHref = item.getAttribute("href") === '#' ? '' : item.getAttribute("href");
        if (window.location.hash  === itemHref) {
            item.classList.add('active');
        } else {
            item.classList.remove('active');
        }
    });
};
